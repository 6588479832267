.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

body {
  background-color: #1b232c;
  color: #202020;
  font-family: Arial;
  text-align: center;
}




.vid {
  display: flex;
  top: 30%; 
  height: 100%;
  width: 100%;
  position: fixed; 
  z-index:+2;
  background: transparent;
  align-items: center;
  flex-direction: column;
}

.background-b {
  background-color: #202020;
  background-position: 0%;
}

.App-header {
  z-index:+3;  
  top: 1%;
  font-size: calc(15px + 1.5vw + 2vh);
  color: rgb(160, 160, 160);
  display: flex;
  position: fixed;
  font-family: 'nimbus';
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.bread {
  z-index:+3;
  text-align: center;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  bottom: 25%;
  left: 0;
  width: 100%;
  color: rgb(100, 100, 100);
  overflow: hidden;
}

.App-footer {
  z-index:+3;
  text-align: center;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  font-family: 'nimbus';
  position: fixed;
  bottom: -0.5%;
  left: 0;
  width: 100%;
  font-size: calc(200%);
  color: rgb(99, 99, 99);
  overflow: hidden;
  text-decoration: none;
}



.cursor {
  z-index:+1;
  width: 15px;
  height: 15px;
  border: 2px solid #121212;
  border-radius: 100%;
  position: fixed;
  transform: translate(-50%, -50%);
  background-color: rgb(255, 53, 104);
}



.App-link {
  color: #61dafb;
}


